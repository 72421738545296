import React, { useState, useEffect } from 'react';
import { Map, View } from 'ol';
import { Tile as TileLayer } from 'ol/layer';
import { OSM } from 'ol/source';
import { fromLonLat, toLonLat } from 'ol/proj';
import axios from 'axios';
import 'ol/ol.css';
import Modal from 'react-modal';
import './index.css';

Modal.setAppElement('#root');

const WeatherApp = () => {
  const [map, setMap] = useState(null);
  const [coords, setCoords] = useState(null);
  const [forecast, setForecast] = useState([]);
  const [geocodedLocation, setGeocodedLocation] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [welcomeIsOpen, setWelcomeIsOpen] = useState(true);
  const [isCelsius, setIsCelsius] = useState(false);

  useEffect(() => {
    const initializeMap = () => {
      const initialMap = new Map({
        target: 'map',
        layers: [
          new TileLayer({ source: new OSM() }),
        ],
        view: new View({
          center: fromLonLat([-118.2426, 34.0549]),
          zoom: 10,
        }),
      });

      initialMap.on('click', async (event) => {
        handleMapClick(event);
      });

      setMap(initialMap);
    };

    initializeMap();
  }, []);

  const handleMapClick = async (event) => {
    const [lon, lat] = toLonLat(event.coordinate);
    setCoords({ lat, lng: lon });
    setLoading(true);

    try {
      const response = await axios.post('https://weather-cast-app-ff2d3c7073de.herokuapp.com/API-data', { lat, lon });
      const { forecast, geocoded_data } = response.data;
      setForecast(forecast);
      setGeocodedLocation(geocoded_data);
      setModalIsOpen(true);
    } catch (error) {
      console.error('Error fetching weather and geocoded data:', error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeWelcomeModal = () => {
    setWelcomeIsOpen(false);
  };

  const toggleTemperatureUnit = () => {
    setIsCelsius(prev => !prev); // Toggle between Celsius and Fahrenheit
  };

  return (
    <div>
      {/* Always mounted modals */}
      <Modal
        isOpen={welcomeIsOpen}
        onRequestClose={closeWelcomeModal}
        className="welcome-modal"
        overlayClassName="overlay"
      >
        <div className="welcome-content">
          <h1>W E A T H E R C A S T</h1>
          <p>A quick 7 day forecast for anywhere on Earth</p>
          <button onClick={closeWelcomeModal} className="close-welcome-button">
            Continue
          </button>
          <div className="author-linkedin">
            <p>Author: Jerry Heo</p>
            <a href="https://www.linkedin.com/in/jerry-heo" target="_blank" rel="noopener noreferrer">
              <img 
                className="linkedin-logo"
                src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" 
                alt="LinkedIn"
              />
            </a>
          </div>
        </div>
      </Modal>

      <div id="map" style={{ height: '100vh', width: '100%' }}></div>

      {loading && (
        <div className="loading-spinner">
          <div className="spinner">
            <div className="sun"></div>
            <div className="cloud"></div>
          </div>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modal-header">
          <div className="title-section">
            <div className="title-with-toggle">
              <h3 className="modal-title">7 Day Forecast</h3>
              <button onClick={toggleTemperatureUnit} className="toggle-button">
                {isCelsius ? '°C' : '°F'}
              </button>
            </div>
            <div className="geocoded-location">
              {geocodedLocation ? (
                `${geocodedLocation.name}, ${geocodedLocation.state}, ${geocodedLocation.country}`
              ) : (
                'Location not available'
              )}
            </div>
          </div>
          <button onClick={closeModal} className="close-button">X</button>
        </div>
        <div className="modal-content">
          {forecast.length > 0 ? (
            forecast.map((day, idx) => (
              <div key={idx}>
                <div className="center-date">{day.date}</div>
                <div className="weather-info">
                  <div className="temp-icon-wrapper">
                    <div className="temp-high-icon">
                      <div className="high-temp">
                        {isCelsius ? `${day.temp_max_c}` : `${day.temp_max_f}`}
                      </div>
                      <img src={day.weather_icon_url} alt={day.weather_main} className="weather-icon" />
                    </div>
                    <div className="low-temp">
                      {isCelsius ? `${day.temp_min_c}` : `${day.temp_min_f}`}
                    </div>
                  </div>
                  <div className="weather-details">
                    <p>Precipitation: {day.pop}%</p>
                    <p>Humidity: {day.humidity}%</p>
                    <p>UVI: {day.uvi}</p>
                    <p>Wind Speed: {day.wind_speed}</p>
                  </div>
                  <p className="summary">{day.summary}</p>
                </div>
                
                {idx < forecast.length - 1 && <hr className="divider" />}
              </div>
            ))
          ) : (
            <p>No forecast data available.</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default WeatherApp;